import React from 'react';
import { Slider, ListingCard } from '../../components';

import css from './ListingPage.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 5;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 3;
const SLIDES_TO_SCROLL_DESKTOP = 5;

const SectionSimilarShowsMaybe = props => {
  const {
    intl,
    listingId,
    similarShows,
    querySimilarShowsInProgress,
    querySimilarShowsError,
  } = props;

  const renderSimilarShows = similarShows.filter(l => l.id.uuid !== listingId.uuid);

  if (querySimilarShowsInProgress || querySimilarShowsError || renderSimilarShows.length === 0) {
    return null;
  }

  const hasSimilarShows = similarShows.length > 0;
  const sliderTitle = intl.formatMessage({ id: 'ListingPage.similarShowsTitle' });

  return hasSimilarShows ? (
    <div className={css.sectionSimilarShows}>
      <div className={css.similarShows}>
        <Slider
          id="ExperiencesSlider"
          headerClassName={css.sliderHeader}
          titleClassName={css.similarShowsTitle}
          title={sliderTitle}
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {renderSimilarShows.map(l => {
            return <ListingCard key={l.id.uuid} listing={l} />;
          })}
        </Slider>
      </div>
    </div>
  ) : null;
};

export default SectionSimilarShowsMaybe;
