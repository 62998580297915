import React, { useState, useEffect, useRef } from 'react';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { findUserShowRatingByListingId, getUserShowRatings } from '../../util/data';
import {
  ResponsiveImage,
  Modal,
  ImageCarousel,
  NamedLink,
  InlineTextButton,
  FollowUser,
  IconShare,
  IconEdit,
  IconReviewStar,
  IconGallery,
  ExpandableBio,
} from '../../components';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const MAX_AVERAGE_REVIEW = 10;
const DESCRIPTION_MAX_LENGTH = 120;

const SectionImages = props => {
  const {
    title,
    richTitle,
    listing,
    currentUser,
    currentAuthor,
    authorDisplayName,
    reviews,
    averageReview,
    organizationProfile,
    posterId,
    isOwnListing,
    imageCarouselOpen,
    editParams,
    handleViewPhotosClick,
    followUserInProgress,
    followUserError,
    unfollowUserInProgress,
    unfollowUserError,
    onFollowUser,
    onUnfollowUser,
    onImageCarouselClose,
    onOpenShareModal,
    onOpenShowRatingModal,
    onOpenDescriptionModal,
    onManageDisableScrolling,
  } = props;

  const elementRef = useRef(null);
  const [descriptionWidth, setDescriptionWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setDescriptionWidth(entry.contentRect.width);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []);

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const posterImage = listing?.images?.find(i => i.id.uuid === posterId) ?? firstImage;

  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const editMessageId = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';

  const showRatings = getUserShowRatings(currentUser);
  const showRating = findUserShowRatingByListingId(showRatings, listing.id.uuid);
  const subscriptions = listing?.attributes?.metadata?.subscriptions?.length || 0;

  // title container
  const titleContainer = (
    <div className={css.titleContainer}>
      {organizationProfile?.id ? (
        <NamedLink
          className={css.organizationProfile}
          name="OrganizationProfilePage"
          params={{ id: organizationProfile.id }}
        >
          {organizationProfile.name}
        </NamedLink>
      ) : null}
      <div className={css.titleAndReviews}>
        <h1 className={css.title}>{richTitle}</h1>

        <div
          className={css.topDescriptionWrapper}
          style={{
            width: descriptionWidth,
          }}
        >
          <ExpandableBio
            className={css.topDescription}
            showMoreClassName={css.topDescriptionShowMore}
            bio={listing?.attributes?.description}
            maxLength={DESCRIPTION_MAX_LENGTH}
            onReadMore={onOpenDescriptionModal}
          />
        </div>

        <div className={css.topReviews} ref={elementRef}>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.rating" />
            </span>
            <span className={css.topReviewsValue}>
              <IconReviewStar isFilled />
              <FormattedMessage
                id="ListingPage.ratingCount"
                values={{ count: averageReview, maxCount: MAX_AVERAGE_REVIEW }}
              />
            </span>
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.yourRating" />
            </span>
            {showRating ? (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar className={css.showRatingStar} isFilled />
                <FormattedMessage
                  id="ListingPage.ratingCount"
                  values={{ count: showRating.rating, maxCount: MAX_AVERAGE_REVIEW }}
                />
              </InlineTextButton>
            ) : (
              <InlineTextButton
                type="button"
                className={css.topReviewsValue}
                onClick={onOpenShowRatingModal}
              >
                <IconReviewStar />
                <FormattedMessage id="ListingPage.rateText" />
              </InlineTextButton>
            )}
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.userReviews" />
            </span>
            <span className={css.topReviewsValue}>{reviews.length}</span>
          </div>
          <div className={css.topReviewsSection}>
            <span className={css.topReviewsHeading}>
              <FormattedMessage id="ListingPage.members" />
            </span>
            <span className={css.topReviewsValue}>{subscriptions}</span>
          </div>
        </div>
      </div>
    </div>
  );

  // view photos button
  const viewPhotosButton = hasImages ? (
    <InlineTextButton className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <IconGallery className={css.galleryIcon} />
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </InlineTextButton>
  ) : null;

  // follow user component
  const showFollowUserButton = false;
  const renderFollowUser =
    showFollowUserButton && !isOwnListing ? (
      <FollowUser
        className={css.authorAction}
        iconClassName={css.authorActionIcon}
        currentUser={currentUser}
        followUserId={currentAuthor?.id?.uuid}
        onFollowUser={onFollowUser}
        onUnfollowUser={onUnfollowUser}
        inProgress={followUserInProgress || unfollowUserInProgress}
        error={followUserError || unfollowUserError}
      />
    ) : null;

  // author info container
  const authorInfo = (
    <div className={css.author}>
      {hasImages ? (
        viewPhotosButton
      ) : currentAuthor?.id ? (
        <NamedLink
          className={css.authorHeading}
          name="ProfilePage"
          params={{ id: currentAuthor?.id?.uuid }}
        >
          {authorDisplayName}
        </NamedLink>
      ) : null}
      <div className={css.authorActions}>
        <InlineTextButton className={css.authorAction} onClick={onOpenShareModal}>
          <IconShare className={css.authorActionIcon} />
          <FormattedMessage id="ListingPage.shareButton" />
        </InlineTextButton>
        {isOwnListing ? (
          <NamedLink className={css.authorAction} name="EditListingPage" params={editParams}>
            <IconEdit className={classNames(css.authorActionIcon, css.editIcon)} />
            <FormattedMessage id={editMessageId} />
          </NamedLink>
        ) : (
          renderFollowUser
        )}
      </div>
    </div>
  );

  const sectionProps = {
    className: css.sectionImages,
  };

  return (
    <div {...sectionProps}>
      <div className={css.heroContentWrapper}>
        <div className={css.heroContent}>
          {authorInfo}
          <div className={css.listingInfo}>
            <div className={css.listingImage}>
              <ResponsiveImage
                rootClassName={css.listingImageRoot}
                alt={title}
                image={posterImage}
                variants={['poster-crop']}
              />
            </div>
            {titleContainer}
          </div>
        </div>
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ImageCarousel
            images={listing.images}
            imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SectionImages;
