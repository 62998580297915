import React from 'react';
import { Slider, ExperienceCard } from '../../components';

import css from './ListingPage.module.css';

// slidesToShow
const SLIDES_TO_SHOW_TABLET = 3;
const SLIDES_TO_SHOW_DESKTOP = 4;
// slidesToScroll
const SLIDES_TO_SCROLL_TABLET = 1;
const SLIDES_TO_SCROLL_DESKTOP = 2;

const SectionExperiencesMaybe = props => {
  const { intl, experiences, queryShowExperiencesInProgress, queryShowExperiencesError } = props;

  if (queryShowExperiencesInProgress || queryShowExperiencesError) {
    return null;
  }

  const hasExperiences = experiences.length > 0;
  const sliderTitle = intl.formatMessage({ id: 'ListingPage.experiencesTitle' });

  return hasExperiences ? (
    <div className={css.sectionExperiences}>
      <div className={css.experiences}>
        <Slider
          id="ExperiencesSlider"
          headerClassName={css.sliderHeader}
          titleClassName={css.experiencesTitle}
          title={sliderTitle}
          slidesToShow={{
            tablet: SLIDES_TO_SHOW_TABLET,
            desktop: SLIDES_TO_SHOW_DESKTOP,
          }}
          slidesToScroll={{
            tablet: SLIDES_TO_SCROLL_TABLET,
            desktop: SLIDES_TO_SCROLL_DESKTOP,
          }}
        >
          {experiences.map(e => {
            return <ExperienceCard key={e.id.uuid} listing={e} />;
          })}
        </Slider>
      </div>
    </div>
  ) : null;
};

export default SectionExperiencesMaybe;
